

const routes = [
  {
    path: '/monitor/events/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/monitor/event/Events.vue')
  },
  {
    path: '/trace/:traceabilityPath/timeline', // OLD
    props: true,
    component: () => import('@/views/monitor/trace/Timeline.vue')
  },
  {
    path: '/monitor/trace/:traceabilityPath/timeline',
    props: true,
    component: () => import('@/views/monitor/trace/Timeline.vue')
  },
  {
    path: '/monitor/label/:labelPath/timeline',
    props: true,
    component: () => import('@/views/monitor/label/Timeline.vue')
  }
]

export default routes
