<template>

      <!-- Tasks and patterns -->
      <SidenavSection v-if="permissions"
        :name="$tc('m.Planning')"
        icon="edit_calendar"
        :navs="[
          // { name: 'Insights', href: `/#/labeling/insights`, active: '/labeling/insights' },
          { name: $tc('m.Task', 2), href: `/#/planning/tasks/list`, active: '/planning/task', minPermissions: { TA: 1 } },
          // { name: $tc('m.Trigger', 2), href: `/#/planning/triggers`, active: '/planning/trigger', minPermissions: { TR: 2 } },
          { name: $tc('m.Pattern', 2), href: `/#/planning/patterns`, active: '/planning/pattern', minPermissions: { PA: 2 } },
          // { name: $tc('m.Forecaster', 2), href: `/#/planning/forecasters`, active: '/planning/forecaster', minPermissions: { FO: 2 } },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="planning"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
