const routes = [
  {
    path: '/screenshot/devices/:requestJwt',
    props: true,
    component: () => import('@/views/screenshot/device/Devices.vue')
  },
  {
    path: '/screenshot/products/:requestJwt',
    props: true,
    component: () => import('@/views/screenshot/product/Products.vue')
  },
  {
    path: '/screenshot/a/ev/:requestJwt', // Action events
    props: true,
    component: () => import('@/views/screenshot/action/ActionEvents.vue')
  },
  {
    path: '/screenshot/l/ev/:requestJwt', // Location events count
    props: true,
    component: () => import('@/views/screenshot/location/LocationEvents.vue')
  },
  {
    path: '/screenshot/hp/ev/:requestJwt', // Haccp points
    props: true,
    component: () => import('@/views/screenshot/haccp/HaccpPointEvents.vue')
  },
  {
    path: '/screenshot/la/e/:requestJwt', // Label expirations
    props: true,
    component: () => import('@/views/screenshot/labeling/LabelExpirations.vue')
  },
  {
    path: '/screenshot/s/va/:requestJwt', // Stock variations
    props: true,
    component: () => import('@/views/screenshot/stock/VariationEvents.vue')
  }
]

export default routes
