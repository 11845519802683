const routes = [
  {
    path: '/haccp/alerts/:page?',
    props: true,
    component: () => import('@/views/haccp/alert/Alerts.vue')
  },
  {
    path: '/haccp/alert/:alertId/:nav?/:page?',
    props: true,
    component: () => import('@/views/haccp/alert/Alert.vue')
  },
  {
    path: '/haccp/haccps/:page?',
    props: true,
    component: () => import('@/views/haccp/haccp/Haccps.vue')
  },
  {
    path: '/haccp/haccp/:haccpId/:nav?/:page?',
    props: true,
    component: () => import('@/views/haccp/haccp/Haccp.vue')
  },
  {
    path: '/haccp/events/:startDate?/:endDate?',
    props: true,
    component: () => import('@/views/monitor/haccp/Events.vue')
  }
]

export default routes
