<template>

      <!-- Schedules and patterns -->
        <SidenavSection v-if="permissions && permissions.HP >= 99"
        :name="$tc('m.Haccp')"
        icon="health_and_safety"
        :navs="[
          { name: $tc('m.janby/haccp/point/', 2), href: `/#/haccp/haccps`, active: ['/haccp/haccps','/haccp/haccp'], count: counts.HP },
          { name: $tc('m.Event', 2), href: `/#/haccp/events`, active: '/haccp/events' },
        ]"
        :permissions="permissions"
        :layout="layout"
        visibleKey="haccp"
        :visibles="visibles"
        :toggleVisible="toggleVisible"
        :isActive="isActive"
        />

</template>

<script>

import SidenavSection from './SidenavSection.vue'

export default {
  props: {
    permissions: {
      type: Object,
      default () { return {} }
    },
    layout: {
      type: Object,
      default () { return { sidenav: 0 } }
    },
    counts: {
      type: Object,
      default () { return {} }
    },
    isActive: {
      type: Function
    },
    toggleVisible: {
      type: Function
    },
    visibles: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    SidenavSection,
  }
}
</script>
